import { ref } from "vue"
import { getFirestore, doc, getDoc } from "firebase/firestore"

// Create a singleton instance
let instance = null

export function useProgram() {
    if (instance) return instance

    const program = ref(null)
    const loading = ref(false)
    const error = ref(null)

    const getProgramNick = () => {
        const storedProgram = localStorage.getItem('program')
        if (storedProgram) {
            const parsed = JSON.parse(storedProgram)
            return parsed.nick
        }

        const hostname = window.location.hostname
        if (hostname === 'localhost') {
            return 'ramiro'
        }

        const parts = hostname.split('.')
        let programNick = null

        if (parts.length > 2 && parts[0] === 'www') {
            programNick = parts[1]
        } else {
            programNick = parts[0]
        }

        if (!programNick) {
            throw new Error("Program not found. Please access through program.yesmkt.com")
        }
        
        return programNick
    }

    const fetchProgram = async () => {
        console.log('Starting fetchProgram')
        const db = getFirestore()
        loading.value = true

        try {
            const programNick = getProgramNick()
            console.log('Program nick:', programNick)
            const docRef = doc(db, `programs/${programNick}`)
            console.log('Fetching document from Firestore...')
            const docSnap = await getDoc(docRef)
            
            if (docSnap.exists()) {
                console.log('Document exists:', docSnap.data())
                program.value = {
                    ...docSnap.data(),
                    nick: programNick
                }
                localStorage.setItem('program', JSON.stringify(program.value))
            } else {
                console.log('Document does not exist')
                throw new Error(`Program ${programNick} not found`)
            }
        } catch (err) {
            console.error('Error details:', {
                message: err.message,
                code: err.code,
                stack: err.stack
            })
            error.value = err.message
            console.error('Error fetching program:', err)
            
            // Try to get from localStorage if fetch fails
            const storedProgram = localStorage.getItem('program')
            if (storedProgram) {
                console.log('Falling back to stored program')
                program.value = JSON.parse(storedProgram)
            }
        } finally {
            loading.value = false
        }
    }

    // Initialize program from localStorage if available
    const storedProgram = localStorage.getItem('program')
    if (storedProgram) {
        program.value = JSON.parse(storedProgram)
    }

    instance = {
        program,
        loading,
        error,
        fetchProgram,
    }

    return instance
}
