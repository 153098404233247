<template>
  <div id="app">
    <div v-if="isLoading" class="loading-screen">
      <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
    </div>
    <div v-else-if="error" class="error-screen">
      <div class="error-message">
        <i class="pi pi-exclamation-triangle" style="font-size: 2rem; color: #E43D21;"></i>
        <p>{{ error }}</p>
      </div>
    </div>
    <template v-else>
      <LoginComponent v-if="!isLoggedIn" :program="program" @login-success="handleLoginSuccess" />
      <component v-if="isLoggedIn" :is="CameraComponent" />
    </template>
  </div>
</template>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

#app {
  background: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  z-index: 9999;
}

.error-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  z-index: 9999;
}

.error-message {
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.error-message p {
  margin-top: 1rem;
  color: #495057;
}
</style>

<script setup>
import { ref, onMounted } from 'vue'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '@/firebase'
import LoginComponent from "@/components/loginComponent.vue"
import { useProgram } from "@/composables/useProgram";

const isLoggedIn = ref(false)
const CameraComponent = ref(null)
const { program, fetchProgram, error } = useProgram()
const isLoading = ref(true)

// Lazy load the CameraComponent only when needed
const handleLoginSuccess = async () => {
  if (!CameraComponent.value) {
    CameraComponent.value = (await import("@/components/photoFrameComponent.vue")).default
  }
}

// Function to set the favicon
const setFavicon = (iconUrl) => {
  if (!iconUrl) return;
  
  const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
  favicon.type = 'image/png';
  favicon.rel = 'icon';
  favicon.href = iconUrl;
  document.head.appendChild(favicon);
}

onMounted(async () => {
  isLoading.value = true
  await fetchProgram()

  if (program.value?.icons?.icon16x16) {
    setFavicon(program.value.icons.icon16x16);
  }

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const { useAuth } = await import('@/composables/useAuth')
      const { validateAccess } = useAuth()
      const accessGranted = await validateAccess(user.uid)

      if (accessGranted) {
        isLoggedIn.value = true
        await handleLoginSuccess()
      } else {
        await signOut(auth)
      }
    } else {
      isLoggedIn.value = false
    }
    isLoading.value = false
  })
})
</script>
